<template>
<div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
    <div class="row justify-content-around">
        <div class="card-body mb-3 col-12">
            <!-- <router-link :to="{name: 'SyllabusCreateCoursesAdmin', params: {idCourse: paramsId}}" class="float-right btn btn-lg btn-current"><i class="fas fa-plus mr-2"></i>Syllabus</router-link> -->
            <h2 class="fw-400 font-lg"><b>Syllabus</b></h2>
        </div>
        <div class="card-body w-100">
            <div class="row">
                <div v-if="!isLoad" class="col-12 text-center">
                    <div class="row">
                        <div v-if="syllabus.length == 0" class="col-12">
                            <form @submit.prevent="postSyllabus()" class="row">
                                <div class="form-group col-12">
                                    <input v-model="form.content" type="text" class="form-control" placeholder="Insert your syllabus name" required>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-current float-right fw-600">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div v-else class="col-12">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <form @submit.prevent="patchSyllabus()" class="row">
                                        <div class="form-group col-10">
                                            <input v-model="syllabus.content" type="text" class="form-control" placeholder="Insert your syllabus name" required>
                                        </div>
                                        <div class="col-2">
                                            <button type="submit" class="btn btn-current btn-lg btn-block float-right fw-600">Update</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter" @keyup="searchTable()">
                                </div>
                                <div class="col-2">
                                    <select class="form-control" v-model="cycle">
                                        <option value="1">Term 1</option>
                                        <option value="2">Term 2</option>
                                        <option value="3">Term 3</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <router-link :to="{name: 'SyllabusPointCreateCoursesAdmin', params: {idSyllabus: syllabus.id}}" class="btn btn-lg btn-block btn-current"><i class="fas fa-plus"></i></router-link>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="table-responsive mw-100 pr-2 ml-0">
                                        <table class="table table-hover table-bordered" id="custom-table">
                                            <thead class="">
                                            <tr>
                                                <th class="border-0 bg-current text-white p-3" scope="col" colspan="2">No</th>
                                                <th class="border-0 bg-current text-white p-3" scope="col">Term</th>
                                                <th class="border-0 bg-current text-white p-3" scope="col">Name</th>
                                                <th class="border-0 bg-current text-white p-3" scope="col">Description</th>
                                                <th class="border-0 bg-current text-white p-3" scope="col">Total Topic</th>
                                                <th class="border-0 bg-current text-white p-3" scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <draggable v-model="syllabus.point" tag="tbody" handle=".handle" :disabled="!drag" @end="reOrder()">
                                            <tr v-for="(row, index) in syllabus.point" :key="index">
                                                <td><a href="javascript:void(0)"><i class="fas fa-bars handle"></i></a></td>
                                                <td>{{ index+1 }}</td>
                                                <td>Term {{ row.cycle }}</td>
                                                <td>{{ row.content }}</td>
                                                <td>{{ row.description }}</td>
                                                <td>{{ row.target ? row.target.length : 0 }}</td>
                                                <td>
                                                    <router-link :to="{name: 'SyllabusPointCoursesAdmin',params: {idCourse: paramsId, idSyllabus: syllabus.id, idPoint: row.id}}" class="btn btn-info btn-sm mr-2"><i class="fas fa-info"></i></router-link>
                                                    <router-link :to="{name: 'SyllabusPointEditCoursesAdmin', params: {idCourse: paramsId, idSyllabus: syllabus.id, idPoint: row.id}}" class="btn btn-warning text-white btn-sm mr-2"><i class="fas fa-edit"></i></router-link>
                                                    <button @click="deleteAlert(row.id)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                            </draggable>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
export default {
    name: 'Syllabus',
    components: {
        draggable
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            drag: true,
            form: {
                content: ''
            },
            syllabus: [],
            cycle: 1,
            tableFilter: '',
        }
    },
    created(){
        this.getSyllabus()
    },
    watch:{
        cycle:function(){
            this.syllabus.point = []
            this.getSyllabus()
        }
    },
    methods:{
        async reOrder() {
            this.drag = false
            var data ={
                point: this.syllabus.point,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/reorder`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.drag = true;
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: 'Failed Reorder!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getSyllabus();
                this.drag = true;
            })
        },
        searchTable(){
            var input, table, tr, td, i, j, txtValue, show;
            input = this.tableFilter.toLowerCase();
            table = document.getElementById("custom-table");
            tr = table.querySelectorAll('tbody tr')
            for (i = 0; i < tr.length; i++) {
                show = false
                td = tr[i].getElementsByTagName("td");
                if (td.length > 0) {
                    for(j = 0; j < td.length; j++) {
                        txtValue = td[j].textContent || td[j].innerText;
                        if (txtValue.toLowerCase().indexOf(input) > -1) {
                            show = true
                        }
                    }
                }
                tr[i].style.display = !show ? "none" : "";
            }

        },
        async getSyllabus(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus?slug=${this.paramsId}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.syllabus = res.data.data
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: 'Failed Load Syllabus!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        async postSyllabus(){
            var data ={
                slug: this.paramsId,
                content: this.form.content,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getSyllabus()
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: 'Failed Create New Syllabus!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        async patchSyllabus(){
            var data ={
                _method: 'patch',
                id: this.syllabus.id,
                slug: this.paramsId,
                content: this.syllabus.content,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getSyllabus()
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus',
                    text: 'Failed Create New Syllabus!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deletePoint(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.getSyllabus()
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Syllabus',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deletePoint(point_id){
            var data = {
                id: point_id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.status == 200){
                    this.$swal({
                        toast: true,
                        title: 'Success Delete Syllabus Unit !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.getSyllabus()
                }
            }).catch((err) => {
                if (err?.response?.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Unit!',
                        text: err?.response?.data?.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Unit!',
                        text: 'Failed Delete Unit!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>